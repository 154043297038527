import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

type TSEO = {
  pageTitle?: string
  pagePath?: string
}

export const SEO = ({ pageTitle, pagePath }: TSEO) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          lang
          description
          siteUrl
          locale
        }
      }
    }
  `)

  const description = data.site.siteMetadata.description

  const title = pageTitle
    ? `${pageTitle} | ${data.site.siteMetadata.title}`
    : data.site.siteMetadata.title

  const url = pagePath
    ? `${data.site.siteMetadata.siteUrl}${pagePath}`
    : data.site.siteMetadata.siteUrl

  const imageUrl = `${data.site.siteMetadata.siteUrl}/ogp.jpg`

  return (
    <Helmet>
      <html lang={data.site.siteMetadata.lang} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={url} />

      <meta property="og:site_name" content={data.site.siteMetadata.title} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={data.site.siteMetadata.locale} />

      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:site" content="@claves_corp" />
    </Helmet>
  )
}
