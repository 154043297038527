export interface themeTypes {
  colors: colorsTypes;
}

export interface colorsTypes {
  white: string;
  yellow: string;
  black: string;
  gray: string;
}

export const colors: colorsTypes = {
  white: '#ffffff',
  yellow: '#FFD78B',
  black: '#111111',
  gray: '#dfdfdf',
};

export const theme: themeTypes = {
  colors,
};
