import * as React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import { colors } from "../common/themeStyle"
import { StaticImage } from "gatsby-plugin-image"

export const Header = () => {
  const menuItems = ["ABOUT", "RECRUIT", "CONTACT"]
  const windowLocation = useLocation()
  const isTopPage = windowLocation.pathname == "/"
  const currentPageTitle = () => {
    switch (windowLocation.pathname) {
      case "/about/":
      case "/about":
        return "ABOUT"
      case "/recruit/":
      case "/recruit":
        return "RECRUIT"
      case "/contact/":
      case "/contact":
        return "エイプリルフール企画だよ！"
        break
      default:
        return "NOT FOUND"
    }
  }

  return (
    <>
      <MenuBarWrapper>
        <MenuBar>
          <Link to="/">
            <StaticImage
              src="../images/kumachon_logo.png"
              alt="株式会社くまちょん"
              style={{ marginBottom: 10, width: 130 }}
            />
          </Link>
          <MenuListWrapper>
            {menuItems.map(item => {
              return (
                <MenuList key={item}>
                  <Link
                    to={`/${item.toLowerCase()}`}
                    style={{ textDecoration: "none", color: colors.black }}
                  >
                    {item}
                  </Link>
                </MenuList>
              )
            })}
          </MenuListWrapper>
        </MenuBar>
      </MenuBarWrapper>
      {!isTopPage && (
        <>
          <Breadcrumbs>
            <Link to="/" style={{ color: colors.black }}>
              TOP
            </Link>
            {" > "}
            <Link
              to={windowLocation.pathname}
              style={{ textDecoration: "none", color: colors.black }}
            >
              {currentPageTitle()}
            </Link>
          </Breadcrumbs>
          {currentPageTitle() == "エイプリルフール企画だよ！" ? (
            <ContactPageTitle>{currentPageTitle()}</ContactPageTitle>
          ) : (
            <BasePageTitle>{currentPageTitle()}</BasePageTitle>
          )}
        </>
      )}
    </>
  )
}

const MenuBarWrapper = styled.div`
  height: auto;
  background-color: ${colors.yellow};
`
const MenuBar = styled.div`
  align-items: center;
  width: calc(100% - 40px);
  max-width: 980px;
  margin: 0 auto;
  padding: 5px 0;
  background-color: ${colors.yellow};
  display: flex;
  justify-content: space-between;
  ${media.lessThan("medium")`
    flex-direction: column;
    padding: 15px 20px;
    width: 100%;
    border-bottom: 2px solid ${colors.yellow};
  `}
`
const MenuListWrapper = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  font-size: 25px;
  ${media.lessThan("medium")`
  font-size: 18px;
  margin-top: 15px;
  `}
`
const MenuList = styled.li`
  margin-right: 30px;
  margin-bottom: 0;
  align-items: center;
  ${media.lessThan("medium")`
    margin: 0 20px;
  `}
`
const Breadcrumbs = styled.div`
  width: calc(100% - 40px);
  max-width: 980px;
  margin: 16px auto 10px;
  ${media.lessThan("medium")`
    margin: 16px auto;
  `}
`
const BasePageTitle = styled.h1`
  font-size: 64.5px;
  color: ${colors.black};
  background-color: ${colors.yellow};
  width: 100%;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${media.lessThan("medium")`
    font-size: 40px;
    height: 80px;
  `}
`
const ContactPageTitle = styled.h1`
  font-size: 64.5px;
  color: ${colors.black};
  background-color: ${colors.yellow};
  width: 100%;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${media.lessThan("medium")`
  font-size: 25px;
  height: 80px;
  `}
`
