import * as React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import { colors } from "../common/themeStyle"

export const Footer = () => {
  const currentTime = new Date()
  return (
    <Copyright>
      © 2022 - {currentTime.getFullYear()} 株式会社CLAVES(クラベス) All rights
      reserved.
    </Copyright>
  )
}

const Copyright = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.black};
  height: 54px;
  color: ${colors.white};
  font-size: 12px;
  font-weight: bold;
  ${media.lessThan("medium")`
    font-size: 8px;
    font-weight: normal;
    line-height: 1.05;
    letter-spacing: 0.8px;
  `}
`
